@import '../../common/styles/global.scss';

.tickersWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 2.5rem 0;
}

.ticker {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 1.6;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: var(--ticker-text-color);
  margin: 1.5rem 0.5rem;
  flex: 1;
  min-width: calc(50% - 1rem);

  h2 {
    color: var(--ticker-amount-color);
    margin: 0;
    flex: 1 1 100%;
  }

  > div {
    margin-left: 0.625rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    color: var(--primary-red);

    svg {
      width: 0.75rem;
      margin-right: 0.125rem;

      path {
        fill: var(--primary-red);
      }
    }

    &.positive {
      color: var(--success);

      svg {
        path {
          fill: var(--success);
        }
      }
    }

    span {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;

      &.up {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    min-width: 20%;

    &:not(:last-child) {
      width: calc(25% - 1rem);
    }
  }
}
