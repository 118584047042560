.container {
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  border: 1px solid #ddd;
  margin: 0 1px;
  height: 100%;
  display: flex;
}

.articleLink {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.image {
  width: 100%;
  height: 178px;
  object-fit: cover;
  border-radius: 2px 2px 0 0;
}

.title {
  margin: 0 0 1rem;
  font-size: 0.875em;
  align-self: stretch;
  flex: 1;
  padding: 0.5rem;
}

.dateWrap {
  padding: 0.5rem;
}
