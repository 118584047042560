@import '../../common/styles/typography.scss';

.container {
  margin: 40px 0;
  padding: 0 16px 40px;
  border-bottom: 1px solid #dfe0e6;
}

.articleLink {
  display: block;
}

.card {
  padding: 24px;
  border-radius: 16px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.title {
  margin: 4px 0 16px;
  font-size: 1.5em;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.image {
  width: 100%;
  height: 100%;
  min-height: 214px;
  object-fit: cover;
}

.synopsis {
  font-size: 0.875em;
  line-height: 1.4;
  color: var(--black);
  text-decoration: none;
}
