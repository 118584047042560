@import './theme.scss';

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

h1 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 2.75rem;
  line-height: 1.2;
  color: var(--black);
  margin: 1rem 0;
}

h2 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 2.125rem;
  line-height: 1.2;
  letter-spacing: 0.25px;
  color: var(--black);
}

h3 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.2;
  color: var(--black);
}

h4 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: var(--black);
}

h5 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: var(--black);
}

h6 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 1.6;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: var(--black);
}

p {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: 0.25px;
  color: var(--black);

  &.highlight {
    font-size: 1.25rem;
  }
}
