.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;

  .loadingText {
    margin-top: 1rem;

    h4 {
      margin: 0;
    }
  }
}
