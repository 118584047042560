.container {
  padding: 0 3rem 2.5rem;
  position: relative;
  margin: 2.5rem 0;

  @media screen and (min-width: 768px) {
    padding: 0 2rem 2.5rem;
  }

  :global(.swiper-slide) {
    height: auto;
  }

  :global(.button) {
    width: 44px;
    height: 44px;
    color: black;
    background: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  :global(.swiperButtonPrev) {
    left: -16px;

    &::after {
      content: 'prev';
      font-family: swiper-icons, sans-serif;
      font-size: 24px;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;
      line-height: 1;
    }
  }

  :global(.swiperButtonNext) {
    right: -8px;

    &::after {
      content: 'next';
      font-family: swiper-icons, sans-serif;
      font-size: 24px;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;
      line-height: 1;
    }
  }
}

.title {
  font-size: 1.125rem;
}
