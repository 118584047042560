.subDistrictInfoWrapper {
  button {
    background: none;
    color: #727682;
    padding: 0;
    cursor: pointer;

    &.active {
      color: var(--black);
      font-weight: bold;
    }
  }

  .mobileNavigation {
    display: block;
  }

  .desktopNavigation {
    display: none;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;

    .desktopNavigation,
    .content {
      padding: 1.5rem;
    }

    .desktopNavigation {
      display: block;
      flex: 1;
      background-color: #f5f5f7;
    }

    .mobileNavigation {
      display: none;
    }

    .content {
      flex: 4;
      border-left: 1px solid #dfe0e6;

      h3 {
        margin-top: 0;
      }
    }
  }
}

.navDropdownItems {
  background: var(--navigation-dropdown-bg-color);
  color: var(--navigation-dropdown-color);
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 1rem;
  border-radius: 0 0 0.5rem 0.5rem;
  z-index: 1;

  ul {
    margin-left: 0.5rem;
  }

  ul ul {
    margin-left: 1rem;
  }
}

.navDropdown {
  background: var(--navigation-dropdown-button-color);
  border-radius: var(--button-border-radius);
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 6px 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  margin-right: 1.5rem;
  cursor: pointer;

  h6 {
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 1.6;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: white;
    margin: 0;
  }

  button {
    color: white;
  }

  svg {
    width: 1rem;
    transform: rotate(90deg);

    path {
      fill: #dfe0e6;
    }
  }

  &.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    > .navDropdownItems {
      display: block;

      a {
        color: inherit;
      }
    }

    > svg {
      transform: rotate(270deg);
    }
  }

  @media screen and (min-width: 768px) {
    min-width: 15rem;
  }
}
