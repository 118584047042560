@import './typography.scss';

body {
  font-size: 16px;
  font-family: var(--font-primary);
}

* {
  box-sizing: border-box;
}

button,
.button {
  font-family: var(--font-primary);
  background: var(--button-primary-background);
  color: var(--button-primary-color);
  border-radius: var(--button-border-radius);
  display: inline-block;
  box-shadow: none;
  outline: none;
  border: none;
  padding: 6px 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.43;
  text-align: center;
  letter-spacing: 0.25px;
  margin-bottom: 0.5rem;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    margin: 1rem 0;
  }
}

a {
  text-decoration: none;
}

p a {
  color: inherit;
  text-decoration: underline;

  &:visited {
    color: inherit;
  }
}

hr {
  background: #d2dcf4;
  border: none;
  height: 1px;
}

.container {
  width: 100%;

  section {
    padding: 1rem;
  }

  @media screen and (min-width: 768px) {
    max-width: 728px;
    margin: 0 auto;

    section {
      padding: 0 0.875rem;
    }
  }
}

.tooltip {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  > * {
    width: 100%;
    margin-top: 0.5rem;

    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }

  .title {
    margin: 0;
    width: 100%;
    font-weight: bold;
    color: var(--primary-blue);

    span {
      color: inherit;
      font-size: 0.75rem;
    }
  }

  h6 {
    color: inherit;
    font-size: 1rem;
    font-weight: 600;
  }

  .stat {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    line-height: 1.5;
    color: #a4a4a4;

    .amount {
      font-size: 1.125rem;
      font-weight: bold;
      text-align: left;
      color: var(--black);
    }
  }

  @media screen and (max-width: 768px) {
    h6 {
      margin: 0.5rem 0;
    }

    .stat {
      width: 50%;

      .amount {
        font-size: 1rem;
      }
    }
  }
}

.hide-on-mobile {
  display: none;
}

.desktop-ad {
  display: none;
}

.mobile-ad {
  display: block;
}

.data-item {
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  .col-layout {
    display: flex;
    margin: 0 -1rem;
  }

  .col {
    flex: 1;
  }

  .col-content {
    padding: 0 1rem;
  }
}

@media screen and (min-width: 768px) {
  .hide-on-mobile {
    display: block;
  }

  .desktop-ad {
    display: block;
  }

  .mobile-ad {
    display: none;
  }
}
