.aboutTheDataSection {
  h3,
  h6 {
    margin: 0.5rem 0;
  }

  .aboutTheDataContentWrapper {
    display: flex;
    flex-flow: column;
    margin-bottom: 1rem;

    .imageWrapper {
      background: #fff;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
      border-radius: 0.4rem;
      margin-bottom: 1rem;
      padding: 0.75rem;
      max-width: 100%;

      img {
        max-width: 100%;
      }
    }
  }

  .aboutTheDataContent {
    margin-bottom: 1rem;

    &:first-child {
      margin-bottom: 1.5rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.sources {
  h6 {
    display: inline-block;
    margin-right: 0.5rem;
    line-height: 1rem;
  }

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.25px;
    text-decoration-line: underline;
    color: #727682;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .aboutTheDataSection {
    .aboutTheDataContentWrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1rem;

      .imageWrapper + div {
        width: calc(100% - (200px + 1.5rem));
      }

      p {
        margin-top: 0;
      }

      .imageWrapper {
        width: 200px;
        height: min-content;
        margin: 0 1.5rem 0 0;
      }
    }
  }
}
