@import '../../common/styles/typography.scss';

.dateStamp {
  font-family: var(--font-primary);
  font-size: 0.875rem;
  line-height: 1.43;
  color: #a4a4a4;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;

  svg {
    width: 1rem;
    margin-right: 0.5rem;

    path {
      fill: #a4a4a4;
    }
  }
}
