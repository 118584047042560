:root,
.theme-en {
  --black: #212121;
  --primary-blue: #21418d;
  --primary-red: #dd1f26;
  --success: #8abd17;
  --font-primary: 'Proxima Nova', sans-serif;
  --button-border-radius: 0.5rem;
  --button-primary-background: var(--black);
  --button-primary-color: white;
  --button-secondary-background: transparent;
  --button-secondary-color: var(--black);
  --top-bar-menu-item-color: var(--primary-blue);
  --navigation-dropdown-button-color: var(--primary-blue);
  --navigation-dropdown-bg-color: #4973d5;
  --navigation-dropdown-color: white;
  --about-the-data-link-color: #a4b9ea;
  --ticker-text-color: #727682;
  --ticker-amount-color: #162b5e;
  --menu-item-transform: none;
  --footer-link-color: var(--primary-blue);
}

.theme-af {
  --primary-blue: #51cfff;
  --primary-red: #f36;
  --success: #59d5ae;
  --font-primary: 'Proxima Nova', sans-serif;
  --button-border-radius: 2px;
  --button-primary-background: var(--primary-red);
  --button-primary-color: white;
  --button-secondary-background: var(--primary-blue);
  --button-secondary-color: white;
  --top-bar-menu-item-color: var(--black);
  --navigation-dropdown-button-color: #2cb6dc;
  --navigation-dropdown-bg-color: #f5f5f5;
  --navigation-dropdown-color: var(--black);
  --about-the-data-link-color: #2698bf;
  --ticker-text-color: #2698bf;
  --ticker-amount-color: var(--black);
  --menu-item-transform: uppercase;
  --footer-link-color: var(--primary-red);
}
