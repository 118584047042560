@import '../../common/styles/global.scss';

.menuItem {
  --spacing: 0.75rem;

  margin: 0;
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 1.6;
  letter-spacing: 0.12em;
  color: #a8abb5;

  &:hover {
    color: #131419;
  }

  a {
    color: inherit;

    &.active {
      color: #131419;
    }
  }

  &:not(:first-child) {
    margin-left: var(--spacing);
  }

  &:not(:last-child) {
    margin-right: var(--spacing);
  }
}

.backToMainSite {
  color: var(--black);
  font-weight: bold;
  font-size: 0.75rem;
  letter-spacing: 0.12em;
  display: flex;
  flex-direction: row nowrap;
  align-items: center;

  svg {
    margin-right: 0.25rem;
    transform: rotate(180deg);

    path {
      fill: var(--black);
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 1rem;
  }
}

.topBar {
  background: #f5f5f7;

  @media screen and (max-width: 768px) {
    display: none;
  }

  .topBarContainer {
    width: 100%;
    max-width: 1060px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.875rem 0;
  }

  .topBarMenuItems {
    display: flex;
    flex-flow: row nowrap;
  }

  .menuItem {
    --spacing: 1rem;

    color: var(--top-bar-menu-item-color);
    text-transform: var(--menu-item-transform);
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.25px;
    position: relative;

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      right: -1rem;
      top: 50%;
      transform: translateY(-50%);
      height: 0.5rem;
      width: 1px;
      background: #dfe0e6;
    }
  }
}

.menuActions {
  .menuActionBase {
    padding: 6px 24px;
    border-radius: var(--button-border-radius);
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.43;
    text-align: center;
    letter-spacing: 0.25px;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .menuActionPrimary {
    @extend .menuActionBase;

    background: var(--button-primary-background);
    color: var(--button-primary-color);

    &:visited {
      color: var(--button-primary-color);
    }
  }

  .menuActionSecondary {
    @extend .menuActionBase;

    background: var(--button-secondary-background);
    color: var(--button-secondary-color);
    font-weight: bold;

    &:visited {
      color: var(--button-secondary-color);
    }
  }

  .menuActionNetwerk {
    @extend .menuActionBase;

    background: #ffe045;
    color: #000014;
    font-weight: bold;

    &:visited {
      color: #000014;
    }
  }
}

.news24TopBar {
  background: var(--black);

  .menuItem {
    color: white;
  }

  .menuActions {
    .menuActionPrimary {
      background: var(--primary-red);
      color: white;
      border-radius: 1rem;

      &:visited {
        color: white;
      }
    }

    .menuActionSecondary {
      color: white;

      &:visited {
        color: white;
      }
    }
  }
}

.navBar {
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
  max-width: 942px;
  margin: 0.5rem auto;

  @media screen and (min-width: 768px) {
    position: sticky;
    top: 0;
    z-index: 1;
    opacity: 0;
    transition: all 0.2s;
    background: white;

    &.active {
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0.875rem 1rem;
    background: #f5f5f7;
  }
}

.navHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}

.desktopNav {
  display: flex;
  flex-flow: row nowrap;

  .menuItem {
    text-transform: uppercase;
    letter-spacing: 0.12em;
  }
}

.navBody {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: 768px) {
    margin-top: 1rem;
  }
}

.siteTitle {
  color: var(--black);

  h2,
  h6 {
    margin: 0;
    line-height: 1;
  }

  h2 {
    font-weight: 600;
    font-size: 1.125rem;
    letter-spacing: 0.25px;
    margin: 0;
  }

  h6 {
    color: #4a4a4a;
  }

  @media screen and (min-width: 768px) {
    h2,
    h6 {
      display: inline-block;
    }

    h2 {
      font-size: 1.5rem;
      margin-right: 0.25rem;
    }
  }
}

.logoWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  .logo {
    padding-right: 0.5rem;
    margin-right: 0.5rem;
    border-right: 1px solid #a4a4a4;
  }

  .mobileLogo {
    display: block;
  }

  .desktopLogo {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .logo {
      padding-right: 1rem;
      margin-right: 1rem;
      border-right: 1px solid #a4a4a4;
    }

    .mobileLogo {
      display: none;
    }

    .desktopLogo {
      display: block;
    }
  }
}

.navBack {
  color: #696969;
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  margin-top: 1rem;

  svg {
    margin-right: 0.25rem;
    transform: rotate(180deg);

    path {
      fill: #696969;
    }
  }
}

.aboutTheDataLink {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  color: var(--about-the-data-link-color);

  svg {
    margin-right: 0.375rem;

    path {
      fill: var(--about-the-data-link-color);
    }
  }
}

.navDropdownItems {
  background: var(--navigation-dropdown-bg-color);
  color: var(--navigation-dropdown-color);
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 1rem;
  border-radius: 0 0 0.5rem 0.5rem;
  z-index: 1;

  ul {
    margin-left: 0.5rem;
  }

  ul ul {
    margin-left: 1rem;
  }
}

.provinceDropdown {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > .navDropdownItems {
    position: relative;
    margin: 0;
    padding: 0 1rem;
    width: 100%;
  }
}

.navDropdown {
  background: var(--navigation-dropdown-button-color);
  border-radius: var(--button-border-radius);
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 6px 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  margin-right: 1.5rem;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    min-width: 15rem;
  }
}

.navDropdown,
.provinceDropdown {
  svg {
    width: 1rem;
    transform: rotate(90deg);

    path {
      fill: #dfe0e6;
    }
  }

  .navDropdownItems {
    svg {
      path {
        fill: var(--navigation-dropdown-color);
      }
    }
  }

  &.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    > .navDropdownItems {
      display: block;

      a {
        color: inherit;
      }
    }

    > svg {
      transform: rotate(270deg);
    }
  }
}

.mobileMenuTrigger {
  display: block;
}

.mobileNavWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1rem;
  z-index: 1;

  .menuItem {
    color: #7796df;
    margin: 1rem 0;
    text-transform: none;
  }

  .aboutTheDataLink {
    justify-content: flex-start;
  }

  .mobileMenuClose {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;

    svg {
      width: 1rem;

      path {
        fill: #a8abb5;
      }
    }
  }

  .navDropdown {
    width: 100%;
  }

  .menuActions {
    margin-top: 1.5rem;
  }

  .mobileNav {
    .menuItem {
      font-weight: normal;
      font-size: 1.125rem;
      line-height: 1.4;
      color: black;
    }
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .mobileMenuTrigger {
    display: none;
  }

  .navDropdownItems {
    li {
      a {
        opacity: 0.7;
      }

      &:hover {
        > a {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navHeader .aboutTheDataLink,
  .desktopNav,
  .navBack {
    display: none;
  }
}
