.footer {
  margin-top: 1.5rem;
  border-top: 1px solid #dfe0e6;
  text-align: center;
  padding-bottom: 1.5rem;

  h6 {
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 1.6;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #a8abb5;
    margin: 1.5rem 0 0.5rem;
  }

  .truthFirstLogo {
    width: 138px;

    + p {
      display: none;
      font-size: 0.75rem;
      letter-spacing: 0.4px;
      color: #727682;
      margin-top: 0;
    }
  }

  .waarheidEersteLogo {
    @extend .truthFirstLogo;

    width: 200px;
  }

  .footerLinks {
    margin: 1.5rem 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .footerLink {
      color: var(--footer-link-color);
      text-transform: var(--menu-item-transform);
      font-weight: bold;
      font-size: 0.75rem;
      letter-spacing: 0.4px;
      margin: 0.5rem 0;
    }
  }

  .socialLinksWrapper {
    .socialLinksHeading {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1.43;
      letter-spacing: 0.25px;
      color: #373b48;
    }

    .socialLink {
      &:not(:last-child) {
        margin-right: 2rem;
      }

      svg {
        width: 1.375rem;

        path {
          fill: black;
        }
      }
    }
  }

  .iabLogo {
    width: 62px;
    margin-top: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  .footer {
    h6 {
      margin: 2.5rem 0 0.5rem;
    }

    .truthFirstLogo {
      + p {
        display: block;
      }
    }

    .footerLinks {
      flex-flow: row wrap;
      margin: 2.5rem 0;

      .footerLink {
        margin: 1rem 0;

        &:not(:first-child) {
          padding-left: 1rem;
        }

        &:not(:last-child) {
          padding-right: 1rem;
          border-right: 1px solid #a8abb5;
        }
      }
    }
  }
}
